.hero-bg {
  position: absolute;
  display: flex;
  justify-content: end;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 100%;
  max-width: 1360px;
  overflow: hidden;
  padding: 0 30px;
  top: 55%;
  left: 34%;
  transform: translateX(-40%) translateY(-45%);
}

@media (max-width: 960px) {
  .hero-bg {
    justify-content: center;
    padding: 0;
  }
}
