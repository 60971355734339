/* src/index.css or src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .clip-path-polygon {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 95%, 0 100%);
  }
  .clip-path-custom-shape {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 99%, 0 100%);
  }
}
